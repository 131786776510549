import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`

background-color: black;
  border-top: 1px solid #373737;
  border-bottom: 1px solid #373737;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 120px;
  width: 100%;
  
  h2 {
    font-size: 38px;
    margin-bottom: 40px;
  color: white;

  }

  @media (max-width: ${size.slightlyLarger}) {
    padding: 80px 20px;
  }
`

export const Subtitle = styled.p`
  color: #FFFFFF;
    opacity: 0.5;
    word-break: break-word;
    max-width: 500px;
    margin-bottom: 40px;
    text-align: center;
`

export const TablesWrapper = styled.div`

padding: 10px 20px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto; 
  `

export const TablesWrapperInner = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
`;

export const TableText = styled.span`
  font-weight: 16px !important;
`

export const TableFileName = styled.span`
  font-size: 16px;
  font-weight: 600;
`;
