import { CopyJobs } from '@config/state/globalSlices/apiSlice/types/locations';
import { TableDownloadButton } from '@shared/components/TableDownloadButton';

import { removeFileExtension,renderFileTypeIcon } from '../../../../../../../../compositions/myStudio/utils';
import * as S from './styles';

type Props = {
  copyJobs: CopyJobs[];
}

const ScriptsMobileTable = ({ copyJobs }: Props) => {

  return (
    <S.TableWrapper>
      { copyJobs?.map((x, index) => (
        <S.TableRow key={index}>
          <S.RowContent>
            <S.RowDate>{new Date(x.lastModified).toLocaleDateString()}</S.RowDate>
            <S.RowInfo>
              <S.RowTitle>{removeFileExtension(x.filename)}</S.RowTitle>
                
              <S.RowSubtitleWrapper>

                { renderFileTypeIcon('text/') }
                <S.RowSubtitle>{x.product}</S.RowSubtitle>
              </S.RowSubtitleWrapper>

            </S.RowInfo>
          </S.RowContent>
          <S.RowIcon>
            <TableDownloadButton link={x.preSignedUrl} ariaLabel="Download File" pageName='Brand DNA' jobType={x.product}/>
          </S.RowIcon>
        </S.TableRow>
      ))}
    </S.TableWrapper>
  );
};

export default ScriptsMobileTable;
