import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import AccountManager from './components/AccountManager';
import Audio from './components/Audio';
import BrandValues from './components/BrandValues';
import Hero from './components/Hero';
import ScriptsSection from './components/ScriptsSection';
import StickyFooter from './components/StickyFooter';
import * as S from './styles';
import { BrandWorkshopProps,SpecificSiteDetailType } from './types';
import { getTracksData,getVoiceoversData } from './utils';

const BrandWorkshop = ({ brandWorkshopsData, accountManagerData, previewMode = false }: BrandWorkshopProps) => {
  const [isAMModalOpen, setAMModalOpen] = useState(false);
  const [specificSiteDetail, setSpecificSiteDetail] = useState<SpecificSiteDetailType | null>(null);

  const { brandId } = useParams();

  const handleAMModalVisibility = () => {
    if (previewMode) {return;}
    
    setAMModalOpen(!isAMModalOpen);
  };

  useEffect(() => {
    if (Array.isArray(brandWorkshopsData?.data)) {
      const getBrandDetails = brandWorkshopsData.data.find(
        (detail: SpecificSiteDetailType) => detail.brandId === brandId
      );

      if (getBrandDetails) {
        setSpecificSiteDetail(getBrandDetails);
      } else {
        setSpecificSiteDetail(null); 
      }
    }
  }, [brandWorkshopsData, brandId]);

  const showTracks = (
    Array.isArray(specificSiteDetail?.musicTrack) &&
    specificSiteDetail?.musicTrack?.length > 0 &&
    specificSiteDetail?.musicTrack?.every((track) => track !== null)
  );
  
  const showBrandValues = (
    Array.isArray(specificSiteDetail?.brandValues?.positiveAdjectives) &&
    specificSiteDetail?.brandValues?.positiveAdjectives?.length > 0 &&
    specificSiteDetail?.brandValues?.positiveAdjectives?.every((value) => value !== null)
  );
  
  const showVoiceovers = Array.isArray(specificSiteDetail?.voiceArtists?.voiceArtists)
    && specificSiteDetail?.voiceArtists?.voiceArtists?.length > 0;

  return (
    <>
      <S.Wrapper>
        {
          previewMode && (
            <S.PreviewNotice>
              <p>Preview Mode</p>
            </S.PreviewNotice>
          )
        }

        <S.SectionOneWrapper>
          {specificSiteDetail?.brandName && <Hero siteName={specificSiteDetail?.brandName} previewMode={previewMode} />}
          {accountManagerData && specificSiteDetail?.brandName && (
            <AccountManager
              image={'https://phmg-styles.s3.eu-west-2.amazonaws.com/images/account-manager-placeholder.svg'}
              name={accountManagerData.data.fullName!}
              isAMModalOpen={isAMModalOpen}
              setAMModalOpen={handleAMModalVisibility}
              isSubmitting={false}
              brandName={specificSiteDetail?.brandName}
              previewMode={previewMode}
            />
          )}
          {showBrandValues && <BrandValues brandValues={specificSiteDetail?.brandValues?.positiveAdjectives} />}
        </S.SectionOneWrapper>

        {
          showTracks && (
            <Audio
              icon="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/track-icon.svg"
              title="Your Track"
              tracks={getTracksData(specificSiteDetail?.musicTrack, specificSiteDetail?.brandName, )}
            />
          )
        }

        {showVoiceovers && (
          <Audio
            icon="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/voice-icon.svg"
            title="Your Voice"
            tracks={getVoiceoversData(specificSiteDetail?.voiceArtists?.voiceArtists ?? [])}
          />
        )}

        {
          specificSiteDetail?.copyJobs && specificSiteDetail?.copyJobs?.length > 0 && (
            <ScriptsSection copyJobs={specificSiteDetail.copyJobs}/>
          )
        }

        {!isAMModalOpen && <StickyFooter setAMModalOpen={handleAMModalVisibility} />}
      </S.Wrapper>
    </>
  );
};

export default BrandWorkshop;
