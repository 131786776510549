import { File } from '@config/state/globalSlices/apiSlice/types/locations';
import { TableDownloadButton } from '@shared/components/TableDownloadButton';

import { removeFileExtension,renderFileTypeIcon } from '../../utils';
import * as S from './styles';

type MobileTableProps = {
  files: File[];
}

const MobileTable = ({ files }: MobileTableProps) => {

  return (
    <S.TableWrapper>
      {files?.map((file, index) => (
        <S.TableRow key={index}>
          <S.RowContent>
            <S.RowDate>{new Date(file.uploaded_at).toLocaleDateString()}</S.RowDate>
            <S.RowInfo>
              <S.RowTitle>{removeFileExtension(file.file_name)}</S.RowTitle>
                
              <S.RowSubtitleWrapper>

                { renderFileTypeIcon(file.content_type) }
                <S.RowSubtitle>{file.job_type}</S.RowSubtitle>
              </S.RowSubtitleWrapper>

            </S.RowInfo>
          </S.RowContent>
          <S.RowIcon>
            <TableDownloadButton link={file.s3_url} ariaLabel="Download File" jobType={file.job_type} pageName='My Studio'/>
          </S.RowIcon>
        </S.TableRow>
      ))}
    </S.TableWrapper>
  );
};

export default MobileTable;
