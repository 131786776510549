import { CopyJobs } from '@config/state/globalSlices/apiSlice/types/locations';
import Table from '@shared/components/Table';
import { TableDownloadButton } from '@shared/components/TableDownloadButton';
import withWindowDimensions from '@shared/utils/windowDimensions';
import { removeFileExtension, renderFileTypeIcon } from 'compositions/myStudio/utils';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ScriptsMobileTable from './components/ScriptsMobileTable';
import * as S from './styles';

type Props = {
  copyJobs: CopyJobs[];
  windowWidth?: number;
}

type Sorting = 'filename' | 'product' | 'lastModified';

const ScriptsSection = ({ copyJobs, windowWidth = 1000 }: Props) => {

  const [sorting, setSorting] = useState<{
    sortBy: Sorting;
    sortOrder: 'asc' | 'desc';
  }>({
    sortBy: 'lastModified',
    sortOrder: 'asc',
  });

  const handleSort = (key: Sorting) => {
    setSorting((prevSorting) => ({
      sortBy: key,
      sortOrder: prevSorting.sortBy === key && prevSorting.sortOrder === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Apply sorting to the copyJobs array
  const sortedCopyJobs = [...copyJobs].sort((a, b) => {
    const { sortBy, sortOrder } = sorting;

    let comparison = 0;
    switch (sortBy) {
      case 'lastModified': {
        comparison = new Date(a.lastModified).getTime() - new Date(b.lastModified).getTime();
    
        break;
      }
      case 'filename': {
        comparison = a.filename.localeCompare(b.filename);
    
        break;
      }
      case 'product': {
        comparison = a.product.localeCompare(b.product);
    
        break;
      }
    // No default
    }

    return sortOrder === 'asc' ? comparison : -comparison;
  });

  return (
    <S.Wrapper>
      <h2>Your Scripts</h2>
      <S.Subtitle>
      Here’s where you’ll find all your latest scripts, each created by our specialist in-house copywriters.
      </S.Subtitle>

      <S.TablesWrapper>
        <S.TablesWrapperInner>
          {windowWidth < 685 ? (
            <ScriptsMobileTable copyJobs={sortedCopyJobs} />
          ) : (
            <Table>
              <Table.Head>
                <Table.Heading />
                <Table.Heading
                  onSort={handleSort}
                  sortKey="filename"
                  currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}
                >
                  Name
                </Table.Heading>
                <Table.Heading
                  onSort={handleSort}
                  sortKey="product"
                  currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}
                >
                  Content
                </Table.Heading>
                <Table.Heading
                  onSort={handleSort}
                  sortKey="lastModified"
                  currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}
                >
                  Date
                </Table.Heading>
                <Table.Heading />
              </Table.Head>
              <Table.Body>
                {sortedCopyJobs.length > 0 &&
                  sortedCopyJobs.map((file) => (
                    <Table.Row key={uuidv4()}>
                      <Table.Cell>{renderFileTypeIcon('text/')}</Table.Cell>
                      <Table.Cell>
                        <S.TableFileName>{removeFileExtension(file.filename)}</S.TableFileName>
                      </Table.Cell>
                      <Table.Cell>
                        <S.TableText>{file.product}</S.TableText>
                      </Table.Cell>
                      <Table.Cell>
                        <S.TableText>{new Date(file.lastModified).toLocaleDateString()}</S.TableText>
                      </Table.Cell>
                      <Table.Cell>
                        <TableDownloadButton
                          link={file.preSignedUrl}
                          ariaLabel="Download File"
                          jobType={'wdw'}
                          pageName="Brand DNA"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          )}
        </S.TablesWrapperInner>
      </S.TablesWrapper>
    </S.Wrapper>
  );
};

export default withWindowDimensions(ScriptsSection);
