import { useEventMutation } from '@config/state/globalSlices/apiSlice/mutations';

import * as S from './styles';

type TableDownloadButtonProps = {
  link: string;
  ariaLabel: string;
  jobType: string;
  pageName: string;
};

export const TableDownloadButton = ({ link, ariaLabel, jobType, pageName }: TableDownloadButtonProps) => {

  const [sendEvent] = useEventMutation();

  const handleClick = () => {

    const eventPayload = {
      jobType,
      page: pageName,
    }

    sendEvent({ eventName: 'File Downloaded', metadata: eventPayload });
    const newTab = window.open(link, '_blank');
    if (newTab) {
      newTab.focus();
    }
  }

  return (
    <S.ButtonWrapper aria-label={ariaLabel} onClick={handleClick}>
      <img src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/download-button.svg" alt="" />
    </S.ButtonWrapper>
  );
};
